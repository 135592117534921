// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.firstQuestionModal {
    position: fixed;
    margin-top: 27px;
    /* top: 0;
    left: 0; */
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.389);
  }
  
  .modal-main {
    position:fixed;
    background: rgba(0, 0, 0, 0.325);
    width: 80%;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }`, "",{"version":3,"sources":["webpack://./src/admin/view/firstQuestionModal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB;cACU;IACV,OAAO;IACP,QAAQ;IACR,+BAA+B;IAC/B,UAAU;IACV,YAAY;IACZ,gCAAgC;EAClC;;EAEA;IACE,cAAc;IACd,gCAAgC;IAChC,UAAU;IACV,YAAY;IACZ,OAAO;IACP,QAAQ;IACR,+BAA+B;EACjC;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,aAAa;EACf","sourcesContent":[".firstQuestionModal {\n    position: fixed;\n    margin-top: 27px;\n    /* top: 0;\n    left: 0; */\n    top:50%;\n    left:50%;\n    transform: translate(-50%,-50%);\n    width:100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.389);\n  }\n  \n  .modal-main {\n    position:fixed;\n    background: rgba(0, 0, 0, 0.325);\n    width: 80%;\n    height: auto;\n    top:50%;\n    left:50%;\n    transform: translate(-50%,-50%);\n  }\n  \n  .display-block {\n    display: block;\n  }\n  \n  .display-none {\n    display: none;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
