// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollable-table-container {
  max-width: 100%;
  overflow-x: auto;
}

/* Spacing for table cells (td) */
.grid-row-spacing td {
  padding-bottom: 10px; /* Space below each cell in a row */
}

/* General styling for tables */
table {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate; /* Needed for border-spacing to work */
  border-spacing: 0 10px; /* Horizontal and vertical spacing */
}

th:first-child, td:first-child {
  width: 200px;
}

th, td {
  width: 200px;
  text-align: center;
  vertical-align: middle;
}

/* Adjusted CSS classes for different scenarios */
.last-column-space {
  width: 30px; /* Adjust as needed for delete button */
}

.last-column-no-space {
  width: 0; /* No extra space when there's only one row */
}

.delete-column-btn {
  margin-left: 10px;
  margin-right: 20px;
}`, "",{"version":3,"sources":["webpack://./src/admin/view/createQuestion.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA,iCAAiC;AACjC;EACE,oBAAoB,EAAE,mCAAmC;AAC3D;;AAEA,+BAA+B;AAC/B;EACE,WAAW;EACX,mBAAmB;EACnB,yBAAyB,EAAE,sCAAsC;EACjE,sBAAsB,EAAE,oCAAoC;AAC9D;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA,iDAAiD;AACjD;EACE,WAAW,EAAE,uCAAuC;AACtD;;AAEA;EACE,QAAQ,EAAE,6CAA6C;AACzD;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".scrollable-table-container {\n  max-width: 100%;\n  overflow-x: auto;\n}\n\n/* Spacing for table cells (td) */\n.grid-row-spacing td {\n  padding-bottom: 10px; /* Space below each cell in a row */\n}\n\n/* General styling for tables */\ntable {\n  width: 100%;\n  table-layout: fixed;\n  border-collapse: separate; /* Needed for border-spacing to work */\n  border-spacing: 0 10px; /* Horizontal and vertical spacing */\n}\n\nth:first-child, td:first-child {\n  width: 200px;\n}\n\nth, td {\n  width: 200px;\n  text-align: center;\n  vertical-align: middle;\n}\n\n/* Adjusted CSS classes for different scenarios */\n.last-column-space {\n  width: 30px; /* Adjust as needed for delete button */\n}\n\n.last-column-no-space {\n  width: 0; /* No extra space when there's only one row */\n}\n\n.delete-column-btn {\n  margin-left: 10px;\n  margin-right: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
