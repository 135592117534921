// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.admin-login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f2f5;
  }
  
  .admin-login-container h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .admin-login-container form {
    display: flex;
    flex-direction: column;
    width: 300px;
  }
  
  .admin-login-container input {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .admin-login-container button {
    padding: 10px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .admin-login-container button:hover {
    background-color: #0056b3;
  }

.error-message {
    color: red;
    font-size: 14px;
    margin-top: -10px;
    margin-bottom: 10px;
  }
  
  
  `, "",{"version":3,"sources":["webpack://./src/components/AdminLogin.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,yBAAyB;EAC3B;;EAEA;IACE,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,YAAY;EACd;;EAEA;IACE,mBAAmB;IACnB,aAAa;IACb,eAAe;IACf,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,eAAe;IACf,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,yBAAyB;EAC3B;;AAEF;IACI,UAAU;IACV,eAAe;IACf,iBAAiB;IACjB,mBAAmB;EACrB","sourcesContent":["\n.admin-login-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n    background-color: #f0f2f5;\n  }\n  \n  .admin-login-container h2 {\n    margin-bottom: 20px;\n    color: #333;\n  }\n  \n  .admin-login-container form {\n    display: flex;\n    flex-direction: column;\n    width: 300px;\n  }\n  \n  .admin-login-container input {\n    margin-bottom: 10px;\n    padding: 10px;\n    font-size: 16px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n  }\n  \n  .admin-login-container button {\n    padding: 10px;\n    font-size: 16px;\n    background-color: #007bff;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n  }\n  \n  .admin-login-container button:hover {\n    background-color: #0056b3;\n  }\n\n.error-message {\n    color: red;\n    font-size: 14px;\n    margin-top: -10px;\n    margin-bottom: 10px;\n  }\n  \n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
