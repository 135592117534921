// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  background-color: white; /* Ensures background is white */
}

.controls {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: #007bff;
  margin: 20px;
}

.dropdown {
  padding: 10px;
  margin-right: 10px;
  border-radius: 4px;
  border: 1px solid #f30d0d;
}

.download-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.download-button:hover {
  background-color: #0056b3;
}

.tree-container {
  width: 100%;
  height: calc(100vh - 100px); /* Adjust height to fit within the viewport minus some margin */
  background-color: white; /* Ensures background is white */
  padding: 20px; /* Adds some padding for aesthetics */
  border: 1px solid #ccc; /* Adds a border around the tree for better visual separation */
  overflow: auto; /* Allows scrolling if the tree overflows */
}

.node {
  cursor: pointer;
}

.node circle {
  fill: #fff;
  stroke: steelblue;
  stroke-width: 3px;
}

.node text {
  font: 12px sans-serif;
}

.link {
  fill: none;
  stroke: #ccc;
  stroke-width: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/admin/view/questionsTreeMap.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;EACZ,uBAAuB,EAAE,gCAAgC;AAC3D;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,cAAc;EACd,YAAY;AACd;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,2BAA2B,EAAE,+DAA+D;EAC5F,uBAAuB,EAAE,gCAAgC;EACzD,aAAa,EAAE,qCAAqC;EACpD,sBAAsB,EAAE,+DAA+D;EACvF,cAAc,EAAE,2CAA2C;AAC7D;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":["\n.container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin: 20px;\n  background-color: white; /* Ensures background is white */\n}\n\n.controls {\n  display: flex;\n  align-items: center;\n  margin-bottom: 20px;\n  color: #007bff;\n  margin: 20px;\n}\n\n.dropdown {\n  padding: 10px;\n  margin-right: 10px;\n  border-radius: 4px;\n  border: 1px solid #f30d0d;\n}\n\n.download-button {\n  padding: 10px 20px;\n  background-color: #007bff;\n  color: white;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n}\n\n.download-button:hover {\n  background-color: #0056b3;\n}\n\n.tree-container {\n  width: 100%;\n  height: calc(100vh - 100px); /* Adjust height to fit within the viewport minus some margin */\n  background-color: white; /* Ensures background is white */\n  padding: 20px; /* Adds some padding for aesthetics */\n  border: 1px solid #ccc; /* Adds a border around the tree for better visual separation */\n  overflow: auto; /* Allows scrolling if the tree overflows */\n}\n\n.node {\n  cursor: pointer;\n}\n\n.node circle {\n  fill: #fff;\n  stroke: steelblue;\n  stroke-width: 3px;\n}\n\n.node text {\n  font: 12px sans-serif;\n}\n\n.link {\n  fill: none;\n  stroke: #ccc;\n  stroke-width: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
